<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :footer="false"
    @close="onClose"
  >
    <div class="list">
      <a-button @click="$refs.addDish.open()">添加</a-button>
      <a-table
        :columns="columns"
        :data-source="list"
        :pagination="false"
        :loading="loading"
        :row-key="(record) => record.id"
        :scroll="{ y: 400, x: 450 }"
      >
        <template #bodyCell="{ column, record, index }">
          <template v-if="column.key === 'index'">
            {{ (page.page - 1) * queryParams.Limit + index + 1 }}
          </template>

          <template v-if="column.key === 'count'">
            <a-tooltip>
              <template #title>减少</template>
              <a v-if="record.count > 0" @click.stop="onMinusQuantity(record)">
                <MinusOutlined />
              </a>
              <span v-else style="color: #ccc">
                <MinusOutlined />
              </span>
            </a-tooltip>
            <a-divider type="vertical" />
            <span>
              <CloseOutlined style="font-size: 10px" />{{ record.count }}
            </span>
            <a-divider type="vertical" />
            <a-tooltip>
              <template #title>增加</template>
              <a @click.stop="onPlusQuantity(record)">
                <PlusOutlined />
              </a>
            </a-tooltip>
          </template>
        </template>
      </a-table>
    </div>
    <AddDish ref="addDish" @ok="onAddDish" />
    <EChangeQuantity ref="eChangeQuantity" @ok="onChangeDishCount" />
  </e-modal>
</template>

<script>
import { defineComponent, ref } from 'vue'
import { FinAccountClass } from '@/apis/fin'
import {
  MinusOutlined,
  CloseOutlined,
  PlusOutlined
} from '@ant-design/icons-vue'
import AddDish from './Dish'
import { message } from 'ant-design-vue'
import EChangeQuantity from '@/components/counter/forms/member/ChangeQuantity'

const api = new FinAccountClass()
export default defineComponent({
  mixins: [],
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const member = ref()
    const title = ref('')
    const list = ref([])

    const onClose = () => {
      loading.value = false
      visible.value = false
    }

    const open = (record) => {
      member.value = record
      title.value = record.customerName + '的仓库'
      visible.value = true

      getDataList()
    }

    const getDataList = () => {
      loading.value = true
      api.getCustomerGoods(member.value.customerId).then((resp) => {
        list.value = resp
        loading.value = false
      })
    }

    const onAddDish = (e) => {
      api.updateCustomerGoods({
        dishId: e.dishId,
        count: e.count,
        remark: e.remark,
        customerId: member.value.customerId,
        type: 1
      }).then(() => {
        message.success('添加成功')
        getDataList()
      })
    }

    const dish = ref()
    return {
      columns: [
        {
          title: '名称',
          key: 'dishName',
          dataIndex: 'dishName'
        },
        {
          title: '数量',
          key: 'count',
          dataIndex: 'count',
          width: 150
        }
      ],
      visible,
      loading,
      member,
      title,
      list,
      dish,

      onClose,
      open,
      getDataList,
      onAddDish
    }
  },

  components: {
    MinusOutlined,
    CloseOutlined,
    PlusOutlined,
    AddDish,
    EChangeQuantity
  },
  methods: {
    onPlusQuantity (item) {
      this.dish = item
      this.$refs.eChangeQuantity.open(item, 1)
    },

    onMinusQuantity (item) {
      this.dish = item
      this.$refs.eChangeQuantity.open(item, 0)
    },
    onChangeDishCount (e) {
      this.onMath(e.id, e.type, e.count)
      this.$refs.eChangeQuantity.handleClose()
    },

    onMath (dishId, type, count) {
      api.updateCustomerGoods({
        dishId: dishId,
        count: count,
        remark: this.dish.remark,
        customerId: this.member.customerId,
        type
      }).then(() => {
        message.success((type === 0 ? '减少' : '添加') + '操作成功')
        this.dish = null
        this.getDataList()
      })
    }
  }
})
</script>

<style lang="less" scoped>
.list{
  width: 100%;
  height: 500px;
}
</style>
