<template>
  <e-modal
    :visible="visible"
    :title="title"
    :width="500"
    :loading="loading"
    @close="onClose"
    @ok="onSubmit((e) => onSubmitFun(e))"
  >
    <a-spin tip="Loading..." :spinning="loading">
      <a-form>
        <e-input
          title="姓名"
          :validate="validateInfos.name"
          v-model:value="modelRef.name"
        />

        <e-input title="手机号" :validate="validateInfos.mobile" v-model:value="modelRef.mobile" />

        <e-radio title="性别" v-model:value="modelRef.sex" :options="sexes" />

        <e-select v-model:value="modelRef.managerId" title="客户经理" :options="guaranteeUsers" />

      </a-form>
    </a-spin>
  </e-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw } from 'vue'
import { Form } from 'ant-design-vue'
import form from '@/mixins/form'
import { CustomerClass } from '@/apis/user'

const useForm = Form.useForm
const api = new CustomerClass()
export default defineComponent({
  mixins: [form],
  emits: ['ok'],
  props: {
    allowRoles: {
      type: Array,
      default: () => []
    }
  },
  setup (props, { emit }) {
    const loading = ref(false)
    const visible = ref(false)
    const isEdit = ref(false)
    const title = ref('')

    const modelRef = reactive(api.modelRef)
    const rulesRef = reactive(api.rulesRef)
    const guaranteeUsers = ref([])

    const { resetFields, validate, validateInfos } = useForm(
      modelRef,
      rulesRef
    )

    const onClose = () => {
      resetFields()
      loading.value = false
      visible.value = false
    }

    return {
      visible,
      loading,
      modelRef,
      validateInfos,
      guaranteeUsers,
      isEdit,
      title,
      resetFields,
      validate,
      onClose,
      sexes: [{ label: '先生', value: 1 }, { label: '女士', value: 2 }]
    }
  },
  methods: {
    async getGuaranteeUsers () {
      const list = this.$store.state.data.ALL_HANDLERS.length > 0 ? this.$store.state.data.ALL_HANDLERS : await this.$store.dispatch('GetAllHandlers')
      const vlist = []
      list.map(e => {
        vlist.push({
          label: e.name,
          value: e.id
        })
      })

      this.guaranteeUsers = vlist
    },
    open (record) {
      if (record) {
        this.modelRef.name = record.customerName
        this.modelRef.mobile = record.customerMobile
        this.modelRef.sex = record.customerSex
        this.modelRef.id = record.customerId
        this.modelRef.managerId = record.managerId

        this.isEdit = true
        this.title = '编辑会员：' + record.customerName
      } else {
        this.title = '创建会员'
        this.isEdit = false
      }

      this.getGuaranteeUsers()
      this.visible = true
    },

    onSubmitFun (e) {
      const data = toRaw(e)

      if (!data.managerId) {
        delete data.managerId
      }

      if (this.isEdit) {
        api.update(data).then(() => {
          this.$message.success('修改成功')
          this.$emit('ok')
          this.onClose()
        })
      } else {
        api.create(data).then(() => {
          this.$message.success('创建成功')
          this.$emit('ok')
          this.onClose()
        })
      }
    }
  }
})
</script>
